@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

@supports (font-variation-settings: normal) {
  html {
    background-color: white;
    overflow: auto;
  }
}

* {
  font-family: "Inter" !important;
}

.navHeader {
  position: fixed;
  top: 0;
  /*opacity: 0.82;*/
  width: 100%;
  z-index: 70;
  background: rgba(255, 255, 255, 0.82);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*border: 0.1px solid #E6E6E6;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);*/
}

.headerRight {
  display: flex;
  flex-direction: row;
  right: 0;
}

.navButtons {
  display: flex;
  align-items: center;
}

.navButtons * {
  padding-right: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  /* identical to box height, or 109% */
  text-decoration: none;
  letter-spacing: -0.5px;

  color: #333333;
}

.navButtons .disabled {
  opacity: 0.1;
}
.navButtons .active {
  color: #3366ff;
}

.navButtons Link {
  padding-right: 10px;
}
.navProfilePicture {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  padding: 4px;
}

.bottomLogoContainer {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 10px;
}
.bottomLogoSubcontainer {
  width: 688px;
}
.bottomLogo {
  padding: 8px;
}
.headerAuthors {
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
}

.disableDecoration {
  text-decoration: none;
}
