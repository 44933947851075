@media only screen and (min-width: 900px) {
  html {
    height: -webkit-fill-available;
  }

  .card-input::placeholder,
  .card-holder::placeholder,
  .card-holder > input::placeholder {
    color: white;
  }
  .logo {
    width: 188px;
    height: 60.71px;

    margin-top: 35px;
    margin-left: 20px;
    margin-bottom: 32px;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 806px;
  }
  .left-side {
    width: 450px;
    margin-left: 20px;
    height: 100%;
  }

  .right-side {
    width: 286px;
    margin-right: 20px;
    height: 100%;
  }
  .order-info {
    width: 286px;
    background: #f7f7f7;
    border-radius: 8px;
    padding-bottom: 20px;
    margin-bottom: 24px;
  }
  .order-info:last-child {
    margin-bottom: 45px;
  }
  .order-info .title {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */
    padding: 15px 0px 10px 12px;
    color: #000000;
  }
  .order-info .divider {
    width: 262px;
    height: 0px;

    border: 1px solid #d2d2d2;
    margin-left: 12px;
  }

  .order-info .dashed-divider {
    width: 262px;
    height: 0px;

    border: 1px dashed #d2d2d2;
    margin-left: 12px;
    margin-top: 16px;
  }

  .order-info .text {
    font-weight: normal;
    font-size: 18px;
    line-height: 130%;
    padding: 16px 0 0 12px;
    color: #222222;
    word-break: break-word;
  }

  .content {
    width: 806px;

    border-radius: 8px;
    margin-top: 52px;
    margin-bottom: 52px;

    background-color: white;
  }

  .page-title {
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 51px;

    padding: 0px 0px 16px 0px;
  }

  .card-container {
    height: 240px;
  }

  .top-card {
    width: 360px;
    height: 210px;
    position: absolute;

    background: #f38e0c;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    z-index: 2;
  }

  .card-title {
    display: flex;
    justify-content: space-between;

    margin: 10px 10px 0 10px;
  }

  .card-title img {
    margin-left: 5.3px;
  }

  .quote-text {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;

    color: #fffffe;
  }

  .quote-price {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    margin-left: 5.3px;

    color: #ffffff;
  }

  .mrg {
    margin-top: 14px;
  }
  .card-number {
    margin-top: 20px;
  }
  .simple-input {
    display: flex;
    flex-direction: column;

    margin-left: 10px;
  }
  .card-number span,
  .card-holder span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 5px;
    color: #f7f9fc;
  }
  .card-number-input,
  .card-holder > input {
    width: 200px;
    height: 34px;

    background: #f4ae53;
    border-radius: 3px;
    border: 0;
    padding: 9px 13px 9px 13px;
    color: white;
    outline: 0;
  }

  .double-group {
    margin-top: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .date-input {
    display: flex;
    flex-direction: column;

    width: 130px;
  }
  .date-input span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 5px;
    color: #f7f9fc;
  }

  .card-expiration-input {
    width: 82px;
    height: 34px;
    left: 262px;
    top: 270px;

    background: #f4ae53;
    border-radius: 3px;
    padding: 9px 13px 9px 13px;
    color: white;
    outline: 0;
    border: 0;
    margin-right: 10px;
  }

  .bottom-card {
    width: 360px;
    height: 210px;
    position: absolute;
    margin-left: 90px;
    margin-top: 30px;
    background: #f38e0c;
    border-radius: 8px;

    display: flex;
    justify-content: flex-end;
  }
  .right-content {
    display: flex;
    flex-direction: column;
  }
  .card-cvv {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    margin-left: 15px;
  }
  .card-cvv span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 5px;
    color: #f7f9fc;
  }

  .cvv-input {
    width: 65px;
    height: 34px;
    left: 262px;
    top: 270px;

    background: #f4ae53;
    border-radius: 3px;
    padding: 9px 13px 9px 13px;
    color: white;
    outline: 0;
    border: 0;
    margin-right: 12px;
  }

  .card-line {
    width: 92px;
    height: 45px;
    margin-top: 36px;
    background: #3d5266;
  }

  ul {
    margin-top: 24px;
  }
  ul li {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    /* or 150% */

    color: #222222;
  }

  .billing-container .title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin-top: 20px;
    margin-bottom: 12px;
  }

  .billing-inputs {
    width: 450px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .billing-inputs .address {
    width: 350px;
  }

  .billing-inputs .zip {
    width: 90px;
  }

  .radios {
    margin-top: 15px;
    margin-bottom: 37px;
  }

  .form-check-input[type="radio"] {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .form-check-input[type="checkbox"] {
    height: 18px;
    width: 18px;
    cursor: pointer;
  }

  label.form-check-label {
    font-size: 18px;
    margin-left: 5px;
    cursor: pointer;
  }

  .submit-button {
    height: 46px;
    width: 450px;
    margin-top: 32px;
    background-color: #f38e0c !important;
    border-color: #f38e0c !important;
    border-radius: 8px;
  }

  .submit-button:hover,
  .submit-button:focus,
  .submit-button:active {
    background-color: #f38e0c !important;
  }

  .submit-button span {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
  }

  .btn-primary:disabled {
    opacity: 0.5;
  }

  .button-spinner {
    margin-right: 10px;
  }

  .success-content {
    height: calc(100vh - 102px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .success-content * {
    width: 608px;
  }

  .success-content .title {
    font-weight: 800;
    font-size: 40px;
    line-height: 51px;
    margin-bottom: 37px;
  }

  .success-content .subtitle {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #222222;

    margin-bottom: 37px;
  }

  .success-content .link {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    color: #3366ff;
    text-align: right;

    text-decoration: none;
  }
  .privacy-main-container {
    margin-top: 30px;
  }

  .privacy-container {
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
  }

  .privacy-muted {
    font-size: 18px;
    line-height: 140%;
    color: #a9a8a8;

    margin-top: 22px;
  }

  .privacy-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 22px */

    color: #000000;
  }

  .privacy-text {
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    color: #333333;
  }

  .privacy-text li:not(:last-child) {
    padding-bottom: 12px;
  }

  .privacy-text ul {
    margin: 0;
  }

  .nav-location {
    cursor: pointer;
  }

  .nav-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .privacy.content {
    padding: 26px 24px 24px 24px;
  }
  .privacy-container-title {
    margin-left: -28px;
  }
}
