body {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/background.jpg");
  background-color: #4d5662;
  background-size: 100% 100%;
  background-size: cover;
  min-height: 100vh;
}

.form-check-input:checked {
  background-color: #f38e0c !important;
  border-color: #f38e0c !important;
}
.body.info {
  height: 100vh;
  overflow: hidden;
}

.info-content.content {
  height: calc(100vh - 102px);
}

.info-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-text {
  font-weight: 405;
  font-size: 25px;
}
